<template>
  <div v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane class="clearfix" v-for="(item, index) in navList" :key="index" :label="item" :name="'' + index">
        <div
          class="patentItem"
          v-for="item in patentList"
          :key="item.id"
          @click="$router.push({ path: '/patentSearch/patentDetail', query: { id: item.id } })"
        >
          <div class="patentName">{{ item.patent.title.original }}</div>
          <div class="proposer">申请人：{{ item.patent && item.patent.applicants[0].name.original }}</div>
          <div class="inventor">
            发明人：
            <span v-for="(item_name, idx) in item.patent.inventors" :key="idx">
              <span v-if="item_name.name && item_name.name.original">{{ item_name.name.original }} </span>
            </span>
          </div>
          <div class="patentType">
            专利类型：{{ item.patent.type === 1 ? '发明专利' : item.patent.type === 2 ? '实用新型' : '外观设计' }}
          </div>
        </div>
      </el-tab-pane>
      <div v-if="navList && navList.length" class="btnsWrap">
        <div :class="rightDisable ? 'rightBtn disableBtn' : 'rightBtn'" @click="handlePage('right')">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div :class="leftDisable ? 'leftBtn disableBtn' : 'leftBtn'" @click="handlePage('left')"><i class="el-icon-arrow-left"></i></div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'tagSwiper',
  props: {
    navList: Array,
    scope: String
  },
  data() {
    return {
      loading: true,
      activeName: '0',
      // 请求参数
      params: {
        pageNum: 1,
        pageSize: 4,
        kw: '', // 关键字
        scope: this.scope, // 查询范围
        type: [1, 2, 3]
      },
      patentList: [], // 专利列表
      total: 0 // 总数
    };
  },
  methods: {
    // 获取专利列表
    async getList() {
      this.loading = true;
      const api = this.$fetchApi.patentList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.loading = false;
        this.total = data.result.rsData[0].rsCount;
        this.patentList = data.result.rsData[0].patents;
      }
    },
    // nav点击事件
    handleClick(item) {
      const kw = item.label;
      this.params.kw = kw;
      this.params.pageNum = 1;
      this.getList();
    },
    // 翻页
    handlePage(direction) {
      if (direction === 'left') {
        this.params.pageNum -= 1;
      } else if (direction === 'right') {
        this.params.pageNum += 1;
      }
      this.getList();
    }
  },
  computed: {
    rightDisable() {
      if (this.params.pageNum * 4 >= this.total) {
        return true;
      } else {
        return false;
      }
    },
    leftDisable() {
      if (this.params.pageNum <= 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    navList(newVal) {
      this.params.kw = newVal[0];
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.clearfix:after {
  content: '.';
  height: 0;
  display: block;
  clear: both;
}
/deep/ .el-tabs__content {
  overflow: visible;
}
/deep/ .el-tabs__item {
  font-size: 18px;
}
/deep/ .el-tabs__header {
  margin-bottom: 0;
}
.patentItem {
  width: 49%;
  height: 180px;
  border: 1px solid #ccc;
  float: left;
  padding: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  transition: all 0.2s;
  div {
    padding-top: 10px;
    color: #888;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .patentName {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 0;
    color: #333;
  }
}
.patentItem:nth-child(even) {
  float: right;
}

.patentItem:hover {
  box-shadow: 0px 0px 10px #ccc;
}

.btnsWrap {
  overflow: hidden;
  padding-top: 10px;
  div {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    float: right;
    text-align: center;
    line-height: 40px;
    margin-left: 10px;
  }
  div.disableBtn {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
}
</style>
