<template>
  <div>
    <div id="wordcloud" style="width:100%; height:30rem;"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import echartWordcloud from 'echarts-wordcloud';
Vue.use(echartWordcloud);

export default {
  name: 'wordcloud',
  methods: {
    // 词云图
    async wordcloudInfo() {
      await this.$fetchData.fetchGet({}, this.$fetchApi.wordcloud.api + '?id=' + this.$route.query.id + '&mobile=').then(res => {
        if (res.code === '200' && res.result && res.result.rsData.length > 0) {
          setTimeout(() => {
            const myEcharts = echarts.init(document.getElementById('wordcloud'));
            const option = {
              series: [
                {
                  type: 'wordCloud',
                  shape: 'circle',
                  left: 'center',
                  top: 'center',
                  width: '90%',
                  height: '90%',
                  right: null,
                  bottom: null,
                  sizeRange: [42, 100],
                  rotationRange: [-90, 90],
                  rotationStep: 45,
                  gridSize: 18,
                  drawOutOfBound: !1,
                  textStyle: {
                    normal: {
                      fontFamily: 'sans-serif',
                      fontWeight: 'bold',
                      color() {
                        return [
                          '#fc8675',
                          '#5ab6df',
                          '#4acacb',
                          '#fc8675',
                          '#5ab6df',
                          '#4acacb',
                          '#fc8675',
                          '#5ab6df',
                          '#4acacb',
                          '#fc8675',
                          '#5ab6df'
                        ][Math.floor(10 * Math.random())];
                      }
                    },
                    emphasis: { shadowBlur: 10, shadowColor: '#333' }
                  },
                  data: res.result.rsData
                }
              ]
            };
            myEcharts.setOption(option);
          }, 500);
        }
      });
    }
  },
  created() {
    this.wordcloudInfo();
  }
};
</script>

<style lang="less" scoped></style>
