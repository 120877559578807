<template>
  <div>
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <!-- header -->
    <div class="bgWrap">
      <!-- 头部 -->
      <div class="detail_header">
        <!-- 专利名称 -->
        <div class="patentNameWrap" v-if="patentDetail.title">
          <span class="patentName">
            {{ patentDetail.title.original || patentDetail.title['zh-cn'] || patentDetail.title.en || '暂无标题' }}
          </span>
          <span class="typeTag" v-if="patentDetail.countries && patentDetail.countries.length">
            {{ patentDetail.countries[0] }}/{{ patentDetail.type == 1 ? '发明' : '实用' }}
          </span>
          <span title="点击进行价值评估" class="assessBtn" @click="toAssess">价值评估</span>
        </div>
        <!-- 申请人 -->
        <div class="proposer">
          申请人：
          <span
            v-if="
              patentDetail.applicants &&
                patentDetail.applicants.length > 0 &&
                patentDetail.applicants[0].name &&
                patentDetail.applicants[0].name.original
            "
            >{{ patentDetail.applicants[0].name.original }}</span
          >
        </div>
        <!-- 申请号/申请日 -->
        <div class="numberAndDate">
          <span class="number">申请号：{{ patentDetail.application_number }}</span>
          <span class="proposer"
            >申请日：{{
              patentDetail.application_date
                ? JSON.stringify(patentDetail.application_date).replace(/^(\d{4})(\d{2})(\d{2})$/, '$1年$2月$3日')
                : '-'
            }}</span
          >
        </div>
        <!-- 发明人 -->
        <div class="inventor">
          发明人：
          <span v-for="(item, idx) in patentDetail.inventors" :key="idx">
            <span v-if="item.name && item.name.original">
              {{ item.name.original }}
              <span v-if="idx + 1 != patentDetail.inventors.length">、</span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <!-- main -->
    <div :class="headerFixed ? 'mainWrap addPadding' : 'mainWrap'">
      <ul @mouseenter="handleShowNav" @mouseleave="showNavList = false" :class="headerFixed ? 'navWrap isFixed' : 'navWrap'">
        <li class="navItemWrap" v-for="(item1, index1) in navList" :key="index1">
          {{ item1.name }}
          <ul v-show="showNavList" class="navItemList">
            <li
              :class="item2.disabled ? 'navItem disabled' : 'navItem'"
              v-for="(item2, index2) in item1.children"
              :key="index2"
              @click="toRoll(item2.ref)"
            >
              {{ item2.name }}
            </li>
          </ul>
        </li>
      </ul>
      <!-- 专利详情 -->
      <div class="contentWrap">
        <!-- 基本信息 -->
        <div class="contentListWrap baseMsgWrap">
          <div class="headlineWrap">
            <div class="headline">基本信息</div>
          </div>
          <div class="contentList">
            <div class="contentItemWrap">
              <div class="subtitle" ref="nameAndAbstract">名称及摘要</div>
              <div class="contentItem">
                <div class="nameWrap">
                  <span class="language" v-if="patentDetail.title && patentDetail.title.original_lang === 'zh-cn'">中</span>
                  <span class="language" v-else>原</span>
                  <span class="name" v-if="patentDetail.title && patentDetail.title.original">{{ patentDetail.title.original }}</span>
                </div>
                <div class="contentText" v-if="patentDetail.abstract && patentDetail.abstract.original">
                  {{ patentDetail.abstract.original }}
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="numAndDate">号码及日期</div>
              <div class="contentItem">
                <div class="numAndDateWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">本阶段公开号</el-col>
                    <el-col :span="7">{{ patentDetail.publication_number }}</el-col>
                    <el-col :span="5">授权日</el-col>
                    <el-col :span="7">-</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">公开历史</el-col>
                    <el-col :span="7">-</el-col>
                    <el-col :span="5">最早优先权日</el-col>
                    <el-col :span="7">{{ patentDetail.earliest_priority_date | dateFormat }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">首次公开日</el-col>
                    <el-col :span="7">{{ patentDetail.earliest_publication_date | dateFormat }}</el-col>
                    <el-col :span="5">失效日</el-col>
                    <el-col :span="7">
                      {{ patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.lapse_date | dateFormat }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="rightsAndBelong">权利及归属</div>
              <div class="contentItem">
                <div class="rightsAndBelongWrap table_two">
                  <el-row type="flex">
                    <el-col :span="5">本阶段申请人</el-col>
                    <el-col :span="19">
                      <div v-if="patentDetail.applicants && patentDetail.applicants.length > 0 && patentDetail.applicants[0].name.original">
                        {{ patentDetail.applicants[0].name.original }}({{ patentDetail.applicants[0].countries[0] }})({{
                          patentDetail.applicants[0].type
                        }})<br />
                        {{ patentDetail.applicants[0].address.original }}
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">当前专利权人</el-col>
                    <el-col :span="19">
                      <p v-if="patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.assignees.length > 0">
                        {{ patentDetail_C.patent_legal_status.assignees[0].name.original }}({{
                          patentDetail_C.patent_legal_status.assignees[0].countries[0]
                        }})({{ patentDetail_C.patent_legal_status.assignees[0].type }})<br />
                        {{ patentDetail.applicants[0].address.original }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">本阶段发明人</el-col>
                    <el-col :span="19">
                      <span v-for="(item, idx) in patentDetail.inventors" :key="idx">
                        <span v-if="item.name && item.name.original">{{ item.name.original }} </span>
                      </span>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">审查员</el-col>
                    <el-col :span="19">
                      <div v-if="patentDetail.examiner">{{ patentDetail.examiner.length ? patentDetail.examiner.join(' ') : '-' }}</div>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">代理人</el-col>
                    <el-col :span="19">
                      <div v-if="patentDetail.agents">
                        {{ patentDetail.agents && patentDetail.agents.length ? patentDetail.agents.join(' ') : '-' }}
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">代理机构</el-col>
                    <el-col :span="19">
                      <div>{{ patentDetail.agencies && patentDetail.agencies.length ? patentDetail.agencies.join(' ') : '-' }}</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="inset">附图</div>
              <div class="contentItem"></div>
            </div>
          </div>
        </div>
        <!-- 法律状态 -->
        <div class="contentListWrap lawStatusWrap">
          <div class="headlineWrap">
            <div class="headline">法律状态</div>
          </div>
          <div class="contentList">
            <div class="contentItemWrap">
              <div class="subtitle" ref="currentLawStatus">当前法律状态</div>
              <div class="contentItem">
                <div class="currentLawStatusWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">当前法律状态</el-col>
                    <el-col :span="7">
                      {{
                        patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.legal_status
                          ? ['', '公开', '授权', '失效'][patentDetail_C.patent_legal_status.legal_status]
                          : '-'
                      }}
                    </el-col>
                    <el-col :span="5">申请日</el-col>
                    <el-col :span="7">{{ patentDetail.application_date | dateFormat }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">首次公开日</el-col>
                    <el-col :span="7">{{ patentDetail.earliest_publication_date | dateFormat }}</el-col>
                    <el-col :span="5">存活期</el-col>
                    <el-col :span="7">{{ survivalTime }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">预计剩余寿命</el-col>
                    <el-col :span="7">{{ residualLife }}</el-col>
                    <el-col :span="5">预计失效日</el-col>
                    <el-col :span="7">
                      {{ patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.lapse_date | dateFormat }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="publicStatus">公知公用状态</div>
              <div class="contentItem">
                <div class="publicStatusWrap">
                  <span>非公知公用 </span>
                  <span style="color: #777;">(根据专利的相关信息判断，当前专利在中国不属于公知技术，未经权利人许可不得实施)</span>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="lawStatusChange">法律状态变更史</div>
              <div class="contentItem">
                <div class="lawStatusChangeWrap">
                  <el-row type="flex">
                    <el-col :span="10">日期</el-col>
                    <el-col :span="3">变更项目</el-col>
                    <el-col :span="11">变更内容</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="10">{{ patentDetail.application_date | dateFormat }}</el-col>
                    <el-col :span="3">申请</el-col>
                    <el-col :span="11">----</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="10">{{ patentDetail.earliest_publication_date | dateFormat }}</el-col>
                    <el-col :span="3">首次公开</el-col>
                    <el-col :span="11">当前法律状态：公开</el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="lawMsgHistory">法律信息历史</div>
              <div class="contentItem">
                <div class="lawMsgHistoryWrap">
                  <el-row type="flex">
                    <el-col :span="10">日期</el-col>
                    <el-col :span="4">类别</el-col>
                    <el-col :span="6">代码</el-col>
                    <el-col :span="4">描述</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="10">{{ patentDetail.earliest_publication_date | dateFormat }}</el-col>
                    <el-col :span="4">公开</el-col>
                    <el-col :span="6">{{
                      patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.legal_status_history[0] && patentDetail_C.patent_legal_status.legal_status_history[0].code
                    }}</el-col>
                    <el-col :span="4">公开</el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 专利评价 -->
        <div class="contentListWrap patentAssessWrap">
          <div class="headlineWrap">
            <div class="headline">专利评价</div>
          </div>
          <div class="contentList">
            <el-row class="warning">
              <el-col :span="1.5">注意：</el-col>
              <el-col :span="22"
                >本页面提供的专利评价信息仅供参考。若您需要明确了解一件专利的重要性、地位和价值，应注意参考多方面特别是业内专家的意见。</el-col
              >
            </el-row>
            <div class="contentItemWrap">
              <div class="subtitle" ref="technology">技术指标</div>
              <div class="contentItem">
                <div class="technologyWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">专利类型</el-col>
                    <el-col :span="7">{{
                      patentDetail.type === 1 ? '发明专利' : patentDetail.type === 2 ? '实用新型' : '外观设计'
                    }}</el-col>
                    <el-col :span="5">被引专利数量</el-col>
                    <el-col :span="7">{{ patentDetail.cited_patents && patentDetail.cited_patents.length }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">权利要求数量</el-col>
                    <el-col :span="7">{{ patentClaim.clm_count || 0 }}</el-col>
                    <el-col :span="5">独立权利要求数量</el-col>
                    <el-col :span="7">{{ patentClaim.iclm_count || 0 }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">专利权质押次数</el-col>
                    <el-col :span="7">{{ patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.pledge.length }}</el-col>
                    <el-col :span="5">专利权转移次数</el-col>
                    <el-col :span="7">{{
                      patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.change_assignees.length
                    }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">发明人数量</el-col>
                    <el-col :span="7">{{ patentDetail.inventors && patentDetail.inventors.length }}</el-col>
                    <el-col :span="5">IPC分类号数量</el-col>
                    <el-col :span="7">{{ patentDetail.ipcs && patentDetail.ipcs.length }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">CPC分类号数量</el-col>
                    <el-col :span="7">{{ patentDetail.cpcs && patentDetail.cpcs.length }}</el-col>
                    <el-col :span="5">PDF全文页数</el-col>
                    <el-col :span="7">{{ patentDetail.fulltext_pages }}</el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="rights">权利指标</div>
              <div class="contentItem">
                <div class="rightsWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">法律效力</el-col>
                    <el-col :span="7">公开</el-col>
                    <el-col :span="5">存活期</el-col>
                    <el-col :span="7">{{ survivalTime }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">基本专利族</el-col>
                    <el-col :span="7">{{ basePatent.rsCount }}件专利，覆盖{{ basePatent.countries && basePatent.countries.length }}个国家/地区</el-col>
                    <el-col :span="5">预期剩余寿命</el-col>
                    <el-col :span="7">{{ residualLife }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">扩展专利族</el-col>
                    <el-col :span="7">{{ extendPatent.rsCount }}件专利，覆盖{{ extendPatent.countries && extendPatent.countries.length }}个国家/地区</el-col>
                    <el-col :span="5"></el-col>
                    <el-col :span="7"></el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="bazaar">市场指标</div>
              <div class="contentItem">
                <div class="bazaarWrap table_two">
                  <el-row type="flex">
                    <el-col :span="5">初始申请人</el-col>
                    <el-col :span="19">
                      <div v-if="patentDetail.applicants">
                        <span>{{
                          patentDetail.applicants[0].name.original + '(' + patentDetail.applicants[0].countries.join(' ') + ')'
                        }}</span>
                        <span>{{ '(' + patentDetail.applicants[0].type + ')' }}</span>
                        <div>{{ patentDetail.applicants[0].address.original }}</div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">当前专利权人</el-col>
                    <el-col :span="19">
                      <div v-if="patentDetail_C.patent_legal_status">
                        <span>
                          {{
                            patentDetail_C.patent_legal_status.assignees[0].name.original +
                              '(' +
                              patentDetail_C.patent_legal_status.assignees[0].countries.join(' ') +
                              ')'
                          }}
                        </span>
                        {{ '(' + patentDetail_C.patent_legal_status.assignees[0].type + ')' }}
                        <div>{{ '' + patentDetail_C.patent_legal_status.assignees[0].address.original }}</div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">申请人数量</el-col>
                    <el-col :span="19">
                      {{ patentDetail.applicants && patentDetail.applicants.length }}
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">专利权人数量</el-col>
                    <el-col :span="19">
                      {{ patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.assignees.length }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 专利家族 -->
        <div class="contentListWrap patentsWrap">
          <div class="headlineWrap">
            <div class="headline">专利家族</div>
          </div>
          <div class="contentList">
            <div class="contentItemWrap">
              <div class="subtitle" ref="basicPatents">基本专利族概况</div>
              <div class="contentItem">
                <div class="basicPatentsWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">族内专利数量</el-col>
                    <el-col :span="7">{{ basePatent.rsCount }}</el-col>
                    <el-col :span="5">申请日区间</el-col>
                    <el-col :span="7">{{ basePatent.minApplicantDate | dateFormat }} - {{ basePatent.maxApplicantDate | dateFormat }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">公开日区间</el-col>
                    <el-col :span="7">{{ basePatent.minPublishDate | dateFormat }} - {{ basePatent.maxPublishDate | dateFormat }}</el-col>
                    <el-col :span="5">国家/地区范围</el-col>
                    <el-col :span="7">
                      {{ basePatent.countries && basePatent.countries.length }}个：
                      <span v-for="(item, index) in basePatent.countries" :key='index'>{{item}} </span>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">包含PCT同族</el-col>
                    <el-col :span="7">{{ basePatent.isPCT ? '是' : '否' }}</el-col>
                    <el-col :span="5">包含EP同族</el-col>
                    <el-col :span="7">{{ basePatent.isEP ? '是' : '否' }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">包含中国同族</el-col>
                    <el-col :span="7">{{ basePatent.isCN ? '是' : '否' }}</el-col>
                    <el-col :span="5">包含美国同族</el-col>
                    <el-col :span="7">{{ basePatent.isUS ? '是' : '否' }}</el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="extentPatents">扩展专利族概况</div>
              <div class="contentItem">
                <div class="extentPatentsWrap table_one">
                  <el-row type="flex">
                    <el-col :span="5">族内专利数量</el-col>
                    <el-col :span="7">{{ extendPatent.rsCount }}</el-col>
                    <el-col :span="5">申请日区间</el-col>
                    <el-col :span="7">{{ extendPatent.minApplicantDate | dateFormat }} - {{ extendPatent.maxApplicantDate | dateFormat }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">公开日区间</el-col>
                    <el-col :span="7">{{ extendPatent.minPublishDate | dateFormat }} - {{ extendPatent.maxPublishDate | dateFormat }}</el-col>
                    <el-col :span="5">国家/地区范围</el-col>
                    <el-col :span="7">
                      {{ extendPatent.countries && extendPatent.countries.length }}个：
                      <span v-for="(item, index) in extendPatent.countries" :key='index'>{{item}} </span>
                    </el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">包含PCT同族</el-col>
                    <el-col :span="7">{{ extendPatent.isPCT ? '是' : '否' }}</el-col>
                    <el-col :span="5">包含EP同族</el-col>
                    <el-col :span="7">{{ extendPatent.isEP ? '是' : '否' }}</el-col>
                  </el-row>
                  <el-row type="flex">
                    <el-col :span="5">包含中国同族</el-col>
                    <el-col :span="7">{{ extendPatent.isCN ? '是' : '否' }}</el-col>
                    <el-col :span="5">包含美国同族</el-col>
                    <el-col :span="7">{{ extendPatent.isUS ? '是' : '否' }}</el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="patentsList">族内专利列表</div>
              <div class="contentItem">
                <div class="patentsListWrap">
                   <el-tabs v-model="activePatent">
                    <el-tab-pane label="基本专利族" name="base">
                      <div class="clockWrap"><div class="clock"></div></div>
                      <div>申请日：{{basePatent.minApplicantDate | dateFormat}}</div>
                      <div>申请号：{{basePatent.patentList && basePatent.patentList[0].id}}</div>
                      <div>法律效力：{{patentDetail_C && patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.legal_status ? ['', '公开', '授权', '失效'][ patentDetail_C.patent_legal_status.legal_status] : '-'}}</div>
                      <div>公开历史：</div>
                      <div>专利名称：{{patentDetail.title && patentDetail.title.original ? patentDetail.title.original : '-'}}</div>
                      <div>优先权：</div>
                      <div>国家：<span v-for="(item, index) in basePatent.countries" :key="index">{{item + ' '}}</span></div>
                    </el-tab-pane>
                    <el-tab-pane label="扩展专利族" name="extend">
                      <div class="clockWrap"><div class="clock"></div></div>
                      <div>申请日：{{extendPatent.minApplicantDate | dateFormat}}</div>
                      <div>申请号：{{extendPatent.patentList && extendPatent.patentList[0].id}}</div>
                      <div>法律效力：{{patentDetail_C && patentDetail_C.patent_legal_status && patentDetail_C.patent_legal_status.legal_status ? ['', '公开', '授权', '失效'][ patentDetail_C.patent_legal_status.legal_status] : '-'}}</div>
                      <div>公开历史：</div>
                      <div>专利名称：{{patentDetail.title && patentDetail.title.original ? patentDetail.title.original : '-'}}</div>
                      <div>优先权：</div>
                      <div>国家：<span v-for="(item, index) in extendPatent.countries" :key="index">{{item + ' '}}</span></div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 关联分析 -->
        <div class="contentListWrap relevanceWrap">
          <div class="headlineWrap">
            <div class="headline">关联分析</div>
          </div>
          <div class="contentList">
            <div class="contentItemWrap">
              <div class="subtitle" ref="wordCloud">词云图</div>
              <div class="contentItem"><word-cloud></word-cloud></div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="inventorPatent">发明人专利</div>
              <div class="contentItem">
                <div class="inventorPatentWrap">
                  <tag-swiper :navList='inventorArr' :scope="'3'"></tag-swiper>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="proposerPatent">同申请人专利</div>
              <div class="contentItem">
                <div class="proposerPatentWrap">
                  <tag-swiper :navList='proposerArr' :scope="'2'"></tag-swiper>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="agentPatent">同代理人专利</div>
              <div class="contentItem">
                <div class="agentPatentWrap">
                  <tag-swiper :navList='agent' :scope="'4'"></tag-swiper>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="agencyPatent">同代理机构专利</div>
              <div class="contentItem">
                <div class="agencyPatentWrap">
                  <tag-swiper :navList='agency' :scope="'4'"></tag-swiper>
                </div>
              </div>
            </div>
            <div class="contentItemWrap">
              <div class="subtitle" ref="similarPatent">相似专利</div>
              <div class="contentItem">
                <alike-swiper :title='patentTitle'></alike-swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tagSwiper from './components/tagSwiper';
import alikeSwiper from './components/alikeSwiper';
import wordCloud from './components/wordcloud';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'patentDetail',
  data() {
    return {
      patentId: this.$route.query.id, // 专利Id
      breadList: [{ name: '知识产权' }, { name: '专利检索', path: '/patentSearch' }, { name: '专利详情' }], // 面包屑导航
      patentDetail: {}, // 专利详情
      patentDetail_C: {}, // 专利详情_l
      patentClaim: {}, // 专利权益
      basePatent: '', // 基本专利
      extendPatent: '', // 扩展专利
      headerFixed: false, // 是否吸顶
      showNavList: false, // 是否显示二级导航
      activePatent: 'base', // 当前专利族
      // 导航列表
      navList: [
        {
          name: '基本信息',
          children: [
            { name: '名称及摘要', ref: 'nameAndAbstract' },
            { name: '号码及日期', ref: 'numAndDate' },
            { name: '权利及归属', ref: 'rightsAndBelong' },
            { name: '附图', ref: 'inset' }
          ]
        },
        {
          name: '法律状态',
          children: [
            { name: '当前法律状态', ref: 'currentLawStatus' },
            { name: '公知公用状态', ref: 'publicStatus' },
            { name: '法律状态变更史', ref: 'lawStatusChange' },
            { name: '专利权许可信息', disabled: true },
            { name: '法律信息历史', ref: 'lawMsgHistory' }
          ]
        },
        {
          name: '引用与被引',
          children: [
            { name: '本专利引用表', disabled: true },
            { name: '本专利被引表', disabled: true },
            { name: '文献被引表', disabled: true }
          ]
        },
        {
          name: '专利评价',
          children: [
            { name: '技术指标', ref: 'technology' },
            { name: '权利指标', ref: 'rights' },
            { name: '市场指标', ref: 'bazaar' }
          ]
        },
        {
          name: '专利家族',
          children: [
            { name: '基本专利族概况', ref: 'basicPatents' },
            { name: '扩展专利族', ref: 'extentPatents' },
            { name: '族内专利列表', ref: 'patentsList' }
          ]
        },
        {
          name: '关联分析',
          children: [
            { name: '发明人专利', ref: 'inventorPatent' },
            { name: '同申请人专利', ref: 'proposerPatent' },
            { name: '同代理人专利', ref: 'agentPatent' },
            { name: '同代理机构专利', ref: 'agencyPatent' },
            { name: '词云图', ref: 'wordCloud' },
            { name: '相似专利', ref: 'similarPatent' }
          ]
        }
      ]
    };
  },
  methods: {
    // 跳转价值评估
    toAssess() {
      this.$router.push({
        path: '/patentAssess',
        query: {
          id: this.patentDetail.publication_number
        }
      });
    },
    // nav吸顶
    handleScroll() {
      // 获取页面滚动距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.headerFixed = scrollTop > 504;
    },
    // 鼠标移入显示二级导航
    handleShowNav() {
      this.showNavList = true;
    },
    // 滚动定位
    toRoll(ref) {
      if (ref) {
        const height = this.$refs[ref].offsetTop;
        window.document.documentElement.scrollTop = height - 90;
      }
    },
    // 获取专利权益
    async getPatentClaim() {
      const api = this.$fetchApi.patentClaim.api + this.patentId;
      const data = await this.$fetchData.fetchGet({}, api);
      if (data.code === '200') {
        this.patentClaim = data.result.rsData[0];
      }
    },
    // 获取专利族
    async getFamily() {
      const api =
        this.$fetchApi.getFamily.api +
        '?pageNum=1&pageSize=3&familyNum=' +
        this.patentDetail.family_number +
        '&inpadocNum=' +
        this.patentDetail.inpadoc_number;
      const data = await this.$fetchData.fetchGet({}, api);
      if (data.code === '200') {
        this.basePatent = data.result.rsData[0];
        this.extendPatent = data.result.rsData[1];
      }
    }
  },
  async created() {
    const id = this.patentId;
    const api = this.$fetchApi.patentDetail.api + id;
    const data = await this.$fetchData.fetchGet({}, api);
    if (data.code === '200') {
      this.patentDetail = data.result.rsData[0].patent;
      this.patentDetail_C = data.result.rsData[0].legal;
      // 获取专利权益
      this.getPatentClaim();
      // 获取专利族
      this.getFamily();
    }
  },
  mounted() {
    // 监听页面滚动
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  filters: {
    // 时间格式化
    dateFormat(v) {
      if (v) {
        const value = String(v);
        return Number(value.substring(0, 4)) + '年' + Number(value.substring(4, 6)) + '月' + Number(value.substring(6, 8)) + '日';
      } else {
        return '';
      }
    }
  },
  computed: {
    // 存活期
    survivalTime() {
      const date1 = this.patentDetail.application_date;
      const date2 = this.patentDetail.earliest_publication_date;
      if (date1 && date2) {
        const year1 = String(date1).substr(0, 4);
        const year2 = String(date2).substr(0, 4);
        const month1 = String(date1).substr(4, 2);
        const month2 = String(date2).substr(4, 2);
        const len = (year2 - year1) * 12 + (month2 - month1);
        return len + '个月';
      } else {
        return '-';
      }
    },
    // 剩余寿命
    residualLife() {
      if (this.patentDetail_C.patent_legal_status) {
        const year1 = String(this.patentDetail_C.patent_legal_status.lapse_date).substr(0, 4);
        const year2 = new Date().getFullYear();
        return year1 - year2 + '年';
      } else {
        return '-';
      }
    },
    // 发明人
    inventorArr() {
      const inventorArr = [];
      if (this.patentDetail.inventors) {
        this.patentDetail.inventors.forEach(item => {
          inventorArr.push(item.name.original);
        });
      }
      return inventorArr;
    },
    // 申请人
    proposerArr() {
      const proposerArr = [];
      if (this.patentDetail.applicants) {
        this.patentDetail.applicants.forEach(item => {
          proposerArr.push(item.name.original);
        });
      }
      return proposerArr;
    },
    // 代理人
    agent() {
      return this.patentDetail.agents;
    },
    // 代理机构
    agency() {
      return this.patentDetail.agencies;
    },
    // 专利名称
    patentTitle() {
      let patentTitle = '';
      if (this.patentDetail.title) {
        patentTitle = this.patentDetail.title.original;
      }
      return patentTitle;
    }
  },
  components: { nfBreadcrumb, tagSwiper, alikeSwiper, wordCloud }
};
</script>

<style lang="less" scoped>
.bgWrap {
  height: 250px;
  background-image: url('~@/assets/images/property/bg123.jpg');
  background-size: cover;
  .detail_header {
    width: 1200px;
    margin: 0 auto;
    color: #ddd;
    padding: 40px 0;
    box-sizing: border-box;
    .patentNameWrap {
      color: #fff;
      padding-bottom: 10px;
      .patentName {
        display: inline-block;
        max-width: 800px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        font-size: 26px;
      }
      .typeTag {
        background: #ee6b36;
        vertical-align: middle;
        font-size: 14px;
        border-radius: 2px;
        margin-left: 40px;
        padding: 4px 6px;
      }
      .assessBtn {
        cursor: pointer;
        background: #5e89d4;
        font-size: 14px;
        border-radius: 2px;
        vertical-align: middle;
        padding: 4px 6px;
        margin-left: 10px;
      }
    }
    .numberAndDate {
      padding-top: 30px;
      padding-bottom: 10px;
      span:first-child {
        margin-right: 60px;
      }
    }
  }
}
.mainWrap.addPadding {
  padding-top: 80px;
}
.mainWrap {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  box-shadow: 0px 0px 10px #ccc;
  .navWrap {
    height: 80px;
    background: #000;
    z-index: 999999;
    .navItemWrap {
      position: relative;
      float: left;
      color: #fff;
      width: 200px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 22px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      border-top: 3px solid #000;
      box-sizing: border-box;
      .navItemList {
        position: absolute;
        bottom: -400px;
        left: -1px;
        width: 200px;
        color: #333;
        text-align: left;
        font-size: 17px;
        height: 400px;
        border: 1px solid #ccc;
        border-top: 0;
        padding-left: 20px;
        padding-top: 10px;
        box-sizing: border-box;
        background: #fff;
        z-index: 999;
        .navItem {
          height: 40px;
          line-height: 40px;
          transition: all 0.4s;
        }
        .navItem:hover {
          color: #1559b8;
        }
        .navItem.disabled {
          color: #ccc;
        }
      }
    }
    .navItemWrap:hover {
      border-left-color: #ccc;
      border-right-color: #ccc;
      border-top-color: #5f8bc9;
      height: 80px;
      box-sizing: border-box;
      background: #fff;
      color: #333;
    }
    .navItemWrap:hover .navItemList {
      z-index: 1000;
      box-shadow: 0px 0px 10px #999;
    }
  }
  .navWrap.isFixed {
    position: fixed;
    width: 1200px;
    top: 0;
  }
  .contentWrap {
    padding: 20px;
    padding-top: 10px;
    .contentListWrap {
      .headlineWrap {
        border-bottom: 1px solid #ccc;
        .headline {
          display: inline-block;
          border-bottom: 2px solid #5f8bc9;
          font-size: 20px;
          font-weight: 700;
          color: #333;
          padding: 10px;
        }
      }
      .contentList {
        .contentItemWrap {
          .subtitle {
            border-left: 2px solid #5f8bc9;
            margin: 20px 0;
            padding: 0 20px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.contentListWrap {
  .table_one {
    .el-row:last-child {
      border-bottom: 1px solid #ccc;
    }
    .el-row {
      border-left: 1px solid #ccc;
      .el-col {
        border: 1px solid #ccc;
        padding: 15px;
        border-bottom: 0;
        border-left: 0;
      }
      .el-col:nth-child(odd) {
        background: #f9f9f9;
      }
      .el-col:nth-child(even) {
        color: #999;
      }
    }
  }
  .table_two {
    .el-row:last-child {
      border-bottom: 1px solid #ccc;
    }
    .el-row {
      border-left: 1px solid #ccc;
      .el-col {
        border: 1px solid #ccc;
        padding: 15px;
        border-bottom: 0;
        border-left: 0;
      }
      .el-col:first-child {
        background: #f9f9f9;
      }
      .el-col:last-child {
        color: #999;
      }
    }
  }
}
// 基本信息
.baseMsgWrap {
  .contentItem {
    .nameWrap {
      font-size: 16px;
      padding-bottom: 20px;
      .language {
        background: #b6c9e6;
        color: #fff;
        padding: 3px;
        margin-right: 20px;
      }
    }
    .contentText {
      color: #666;
      line-height: 30px;
      text-align: justify;
    }
  }
}
// 法律状态
.lawStatusWrap {
  // 当前法律状态
  .currentLawStatusWrap {
  }
  // 公知公用状态
  // 法律状态变更史
  .lawStatusChangeWrap {
    .el-row:first-child {
      background: #f9f9f9;
      border-top: 1px solid #ccc;
    }
    .el-row {
      border-left: 1px solid #ccc;
      .el-col {
        padding: 15px;
        border: 1px solid #ccc;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  // 法律信息历史
  .lawMsgHistoryWrap {
    .el-row:first-child {
      background: #f9f9f9;
      border-top: 1px solid #ccc;
    }
    .el-row {
      border-left: 1px solid #ccc;
      .el-col {
        padding: 15px;
        border: 1px solid #ccc;
        border-top: 0;
        border-left: 0;
      }
    }
  }
}
// 专利评价
.patentAssessWrap {
  .warning {
    margin-top: 20px;
    padding: 15px;
    background: #fff5e6;
    color: #fd9b0a;
  }
}
// 专利家族
.patentsWrap {
  .el-tabs {
    /deep/ .el-tabs__nav-wrap::after {
      height: 0 !important;
    }
    /deep/ .el-tabs__item {
      border: 1px solid #ccc;
      padding: 0 15px;
      height: 35px;
      font-size: 16px;
      line-height: 35px;
    }
    /deep/ .el-tabs__item:hover {
      color: #333;
    }
    /deep/ .el-tabs__item.is-active {
      background: #eee;
      color: #333;
    }
    /deep/ .el-tabs__item:last-child {
      border-left: 0;
    }
    /deep/ .el-tabs__active-bar {
      height: 0;
    }
    /deep/ .el-tabs__content {
      overflow: visible;
      position: relative;
      border-left: 1px dashed #ccc;
      margin-left: 15px;
      padding-left: 20px;
      color: #666;
      font-size: 16px;
      line-height: 25px;
      .clockWrap {
        position: absolute;
        left: -10px;
        width: 20px;
        height: 20px;
        background: #779CD1;
        .clock {
          width: 10px;
          height: 10px;
          border: 2px solid #fff;
          border-radius: 50%;
          margin: 3px auto;
          &::before {
            content: '';
            display: block;
            margin: 2px auto;
            width: 2px;
            background: #fff;
            height: 4px;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 55%;
            top: 60%;
            transform: translate(-50%, -50%) rotate(125deg);
            width: 2px;
            background: #fff;
            height: 4px;
          }
        }
      }
    }
  }
}
</style>
